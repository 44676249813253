import React from 'react';
import Navbar from '../components/navbar.js';
import bg3 from "../assect/images/bg/03.jpg" // Corrected typo from 'assect' to 'assets'
import halfImage from '../assect/images/build3.jpeg'
import image6 from"../assect/images/build6.jpeg"
import Footer from "../components/footer";
import CarouselSection from '../components/carsoul.js'

function Construction() {
  return (
    <div>
      {/* Navbar */}
      <Navbar navClass="defaultscroll sticky" logolight={true} menuClass="navigation-menu nav-left nav-light" />
      
      {/* Background Section */}
      <section 
        className="bg-half-170 d-table w-100" 
        style={{
          backgroundImage: `url(${bg3})`,   // Corrected string interpolation with backticks
          backgroundSize: 'cover',          // Makes the background image cover the entire section
          backgroundPosition: 'center',     // Centers the background image
          height: '100vh',                  // Full viewport height
          position: 'relative'
        }}
      >
        <div className="bg-overlay bg-gradient-overlay-2"></div>
        <div className="container">
          <div className="row mt-5 justify-content-start"> {/* Changed to justify-content-start */}
            <div className="col-12">
              <div className="title-heading text-left" style={{ paddingLeft: '50px', paddingTop: '30px' }}> {/* Aligned text left, adjusted padding */}
                <h1 className="mb-0 sub-heading text-white title-dark"style={{lineHeight:"80px"}}>Shaping Your Future : <span style={{ color: 'white' }}>Your Dream Home</span></h1>
                <p className="text-light para-desc mb-0" style={{color: '#f8f9fa', fontWeight: '700', fontSize: '1.3rem', textAlign: 'left'}}>
                  At BMI Real Estate, we are redefining the art of homebuilding in Indore. Known for our exceptional craftsmanship and contemporary designs, we go beyond constructing homes—we create living spaces that blend comfort with modern aesthetics. Our mission is to transform your vision into reality, offering you not just a house, but a lifestyle of luxury and ease.
                </p>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section className="container mt-5 text-center">
  <h2 
    className="mb-4 text-center" 
    style={{ 
      fontSize: '2.5rem', 
      fontWeight: 'bold', 
      color: '#333', 
      marginTop: '20px' // Adjust this value as needed
    }}
  >
    Dream Home <span style={{ color: '#326bbe' }}>We Deliver</span>
  </h2>
</section>

{/* Image and Content Section */}
<section className="container mt-3" style={{ marginBottom: '50px' }}> {/* Add margin-bottom here */}
  <div className="row align-items-center">
    {/* Left Side for Image */}
    <div className="col-lg-6 col-md-12">
      <img 
        src={halfImage} 
        alt="BMI Construction Project" 
        className="img-fluid rounded" // Added rounded corners for style
        style={{
          width: '100%',
          height: '450px',
          boxShadow: '0 4px 8px rgba(0, 0, 0, 0.1)', // Shadow effect
          borderRadius: '10px', // Optional rounded corners
        }}
      />
    </div>

    {/* Right Side for Content */}
    <div className="col-lg-6 col-md-12 d-flex justify-content-center align-items-center">
      <div style={{ padding: '40px', marginTop: '20px' }}> {/* Add marginTop here */}
        <h2 className="mb-3" style={{ fontSize: '2.5rem', fontWeight: 'bold', color: '#333' }}>
          Welcome to <span style={{ color: '#326bbe' }}>BMI</span> Construction
        </h2>
        <h3>"Building Dreams, Creating Reality"</h3><br/>
        <p style={{ fontSize: '1.3rem', lineHeight: '1', color: '#333', marginBottom: '20px' }}>
          At BMI Construction, we are committed to turning visions into reality with precision and care. 
          With years of experience in the industry, we blend innovation, craftsmanship, and integrity to deliver 
          residential and commercial spaces that elevate everyday living. Our passion for excellence drives us to 
          create structures that not only meet your needs but also inspire and endure for generations to come.
        </p>
        <p style={{ fontSize: '1.3rem', lineHeight: '1.3', color: '#333' }}>
          Contact us today to learn how we can help build your dream home!
        </p>
      </div>
    </div>
  </div>
</section>

<section className="container mt-3" style={{ marginTop: '50px' }}> {/* Add margin-top here for more space */}
  <div className="row align-items-center flex-column-reverse flex-sm-row">
    {/* Left Side for Content */}
    <div className="col-lg-6 col-md-12 d-flex justify-content-center align-items-center">
      <div style={{ padding: '40px', marginTop: '20px' }}>
        <h3>Where Quality Meets Innovation</h3><br />
        <p style={{ fontSize: '1.3rem', lineHeight: '1.5', color: '#333', marginBottom: '20px' }}>
          At BMI Construction, we believe in building more than just structures; we build lasting relationships and vibrant communities. With a strong foundation of expertise and a commitment to excellence, we strive to deliver exceptional homes and commercial spaces tailored to your needs. Our team is dedicated to innovative design, superior craftsmanship, and sustainability, ensuring that every project reflects our passion for quality and our promise of durability. Let us help you create a space that embodies your vision and enhances your lifestyle.
        </p>
      </div>
    </div>

    {/* Right Side for Image */}
    <div className="col-lg-6 col-md-12">
      <img 
        src={image6} 
        alt="BMI Construction Project" 
        className="img-fluid rounded" // Added rounded corners for style
        style={{
          width: '100%',
          height: '450px',
          boxShadow: '0 4px 8px rgba(0, 0, 0, 0.1)', // Shadow effect
          borderRadius: '10px', // Optional rounded corners
        }}
      />
    </div>
  </div>
</section>
<section className="container mt-5">
  <div>
    <h2>Our Recent Project</h2>
  </div>
  <div>
    <p style={{ fontSize: '1.5rem', lineHeight: '1.6', color: '#333' }}>
      Discover our latest project that encapsulates our vision of modern living. 
      With meticulous attention to detail and a focus on sustainable practices, 
      we strive to create homes that enhance the quality of life.
    </p>
  </div>
</section>
<section>
<CarouselSection/>
</section>

      
<Footer/>
    </div>
  );
}

export default Construction;