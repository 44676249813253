import React from "react";
import { Link } from "react-router-dom";
import { useState } from "react";
import Navbar from "../components/navbar";
import bg07 from "../assect/images/bg/bg07.jpg"
import {FiPhone, FiHexagon, FiMapPin,FiMail} from "../assect/icons/vander"
import Footer from "../components/footer";
export default function ContactUs(){
    //nodemailer

  const [email, setEmail] = useState("");
  const [phone, setPhone] = useState("");
  const [subject, setSubject] = useState("");
  const [message, setMessage] = useState("");
  const [myname, setName] = useState("");

  const baseUrl = "http://localhost:8000";

  const sendEmail = async (e) => {
    e.preventDefault(); // Prevent form submission refresh
    let dataSend = {
      myname: myname,
      email: email,
      phone: phone,
      subject: subject,
      message: message,
    };

    try {
      const res = await fetch(`${baseUrl}/send-email`, {
        method: "POST",
        body: JSON.stringify(dataSend),
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
        },
      });

      // Handle success and failure
      if (res.ok) {
        alert("Email sent successfully!");
        setName(""); // Clear input fields after sending
        setEmail("");
        setPhone("");
        setSubject("");
        setMessage("");
      } else {
        alert("Failed to send email. Please try again.");
      }
    } catch (error) {
      console.error("Error sending email:", error);
      alert("An error occurred. Please try again later.");
    }
  };


    return(
        <>
        <Navbar navClass="defaultscroll sticky" logolight={true} menuClass = "navigation-menu nav-left nav-light"/>
        <section className="bg-half-170 d-table w-100" style={{backgroundImage:`url(${bg07})`}}>
            <div className="bg-overlay bg-gradient-overlay-2"></div>
            <div className="container">
                <div className="row mt-5 justify-content-center">
                    <div className="col-12">
                        <div className="title-heading text-center">
                            <p className="text-white-50 para-desc mx-auto mb-0">Get in touch !</p>
                            <h5 className="heading fw-semibold mb-0 sub-heading text-white title-dark">Contact us</h5>
                        </div>
                    </div>
                </div>
                <div className="position-middle-bottom">
                    <nav aria-label="breadcrumb" className="d-block">
                        <ul className="breadcrumb breadcrumb-muted mb-0 p-0">
                            <li className="breadcrumb-item"><Link to="/">BMI Real Estate</Link></li>
                            <li className="breadcrumb-item active" aria-current="page">Contact us</li>
                        </ul>
                    </nav>
                </div>
            </div>
        </section>
        <div className="position-relative">
            <div className="shape overflow-hidden text-white">
                <svg viewBox="0 0 2880 48" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M0 48H1437.5H2880V0H2160C1442.5 52 720 0 720 0H0V48Z" fill="currentColor"></path>
                </svg>
            </div>
        </div>
        <section className="section pb-0">
            <div className="container">
                <div className="row justify-content-center">
                    <div className="col-lg-8">
                        <div className="p-4 rounded-3 shadow">
                            <form onSubmit={sendEmail}>
                                <div className="row">
                                    <div className="col-md-6">
                                        <div className="mb-3">
                                            <label className="form-label">Your Name <span className="text-danger">*</span></label>
                                            <input required name="name" id="name" type="text" className="form-control" placeholder="Name :" onChange={(e)=> setName(e.target.value)} value={myname}/>
                                        </div>
                                    </div>
    
                                    <div className="col-md-6">
                                        <div className="mb-3">
                                            <label className="form-label">Your Email <span className="text-danger">*</span></label>
                                            <input required name="email" id="email" type="email" className="form-control" placeholder="Email :" onChange={(e)=> setEmail(e.target.value)} value={email}/>
                                        </div> 
                                    </div>

                                    <div className="col-12">
                                        <div className="mb-3">
                                            <label className="form-label">Phone<span className="text-danger">*</span></label>
                                            <input required name="phone" id="subject" className="form-control" placeholder="Phone number :" onChange={(e)=> setPhone(e.target.value)} value={phone}/>
                                        </div>
                                    </div>
    
                                    {/* <div className="col-12">
                                        <div className="mb-3">
                                            <label className="form-label">Subject</label>
                                            <input name="subject" id="subject" className="form-control" placeholder="Subject :" onChange={(e)=> setSubject(e.target.value)} value={subject}/>
                                        </div>
                                    </div> */}
    
                                    <div className="col-12">
                                        <div className="mb-3">
                                            <label className="form-label">Comments <span className="text-danger">*</span></label>
                                            <textarea name="comments" id="comments" rows="4" className="form-control" placeholder="Message :" onChange={(e)=> setMessage(e.target.value)} value={message}></textarea>
                                        </div>
                                    </div>
                                </div>
                                <div className="row">
                                    <div className="col-12">
                                        <div className="d-grid">
                                            <button type="submit" id="submit" name="send" className="btn btn-primary">Send Message</button>
                                        </div>
                                    </div>
                                </div>
                            </form>
                        </div>
                    </div>
                </div>
            </div>

            <div className="container mt-100 mt-60">
                <div className="row g-4">
                    <div className="col-md-4">
                        <div className="position-relative features text-center mx-lg-4 px-md-1">
                            <div className="feature-icon position-relative overflow-hidden d-flex justify-content-center">
                                <FiHexagon className="hexagon"/>
                                <div className="position-absolute top-50 start-50 translate-middle">
                                    <FiPhone className="fea icon-m-md text-primary"/>
                                </div>
                            </div>
    
                            <div className="mt-4">
                                <h5 className="mb-3">Phone</h5>
                                <p className="text-muted">Right Investment is waiting, call us and discuss</p>
                                <Link to="tel:+152534-468-854" className="text-primary">+91 6262137006</Link>
                            </div>
                        </div>
                    </div>
                    
                    <div className="col-md-4">
                        <div className="position-relative features text-center mx-lg-4 px-md-1">
                            <div className="feature-icon position-relative overflow-hidden d-flex justify-content-center">
                                <FiHexagon className="hexagon"/>
                                <div className="position-absolute top-50 start-50 translate-middle">
                                    <FiMail className="fea icon-m-md text-primary"/>
                                </div>
                            </div>
    
                            <div className="mt-4">
                                <h5 className="mb-3">Email</h5>
                                <p className="text-muted">Drop us your details and get connected </p>
                                <Link to="mailto:contact@example.com" className="text-primary">info@bmiinfratech.com</Link>
                            </div>
                        </div>
                    </div>
                    
                    <div className="col-md-4">
                        <div className="position-relative features text-center mx-lg-4 px-md-1">
                            <div className="feature-icon position-relative overflow-hidden d-flex justify-content-center">
                               <FiHexagon className="hexagon"/>
                                <div className="position-absolute top-50 start-50 translate-middle">
                                    <FiMapPin className="fea icon-m-md text-primary"/>
                                </div>
                            </div>
    
                            <div className="mt-4">
                                <h5 className="mb-3">Location</h5>
                                <p className="text-muted">213, Apollo Premier, Vijay Nagar square, <br/>Indore, INDIA 452001</p>
                                <Link to="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3679.4143230181917!2d75.89420117435883!3d22.750000326446862!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x39630390e137d0d7%3A0x5bef82fe270e4409!2sApollo%20Premier!5e0!3m2!1sen!2sin!4v1727331224510!5m2!1sen!2sin" data-type="iframe" className="text-primary lightbox">View on Google map</Link> 
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div className="container-fluid mt-100 mt-60">
                <div className="row">
                    <div className="col-12 p-0">
                        <div className="card map border-0">
                            <div className="card-body p-0">
                                <iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3679.4143230181917!2d75.89420117435883!3d22.750000326446862!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x39630390e137d0d7%3A0x5bef82fe270e4409!2sApollo%20Premier!5e0!3m2!1sen!2sin!4v1727331224510!5m2!1sen!2sin" style={{border:"0" }} title="Townter" allowFullScreen></iframe>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
        <Footer/>
        </>
    )
}