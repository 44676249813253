import React from 'react';
import Navbar from '../components/navbar.js';
import main_img from '../assect/images/mineofmine.jpg';
import limestone from '../assect/images/limestone.jpeg';
import bauxite_img from '../assect/images/bauxite_img.jpeg'
import coal_img from '../assect/images/coal_img.jpeg'
import iron_img from '../assect/images/istockphoto-175494903-2048x2048-transformed.webp'
import stone_img from '../assect/images/stone_img2.jpeg'
import sand_img from '../assect/images/sand_img.jpeg'
import Footer from "../components/footer";
import CarouselSection from '../components/miningcarsoul.js'

function Mining() {
  return (
    <>
      <Navbar navClass="defaultscroll sticky" logolight={true} menuClass="navigation-menu nav-left nav-light" />
      
      {/* Background Section */}
      <section className="bg-half-170 d-table w-100" style={{backgroundImage:`url(${main_img})`}}>
            <div className="bg-overlay bg-gradient-overlay-2"></div>
            <div className="container">
                <div className="row mt-5 justify-content-center">
                    <div className="col-12">
                        <div className="title-heading text-center">
                            {/* <p className="text-white-50 para-desc mx-auto mb-0">Questions & Answers</p> */}
                            {/* <h5 className="heading fw-semibold mb-0 sub-heading text-white title-dark">Frequently </h5> */}
                            <h1 className="mb-0 sub-heading text-white title-dark" style={{ lineHeight: "50px", fontSize: "12rem" }}>
                  Explore BMI Real Estate: <span style={{ color: '#FFD700' }}>Mining</span>
                </h1>
                        </div>
                    </div>
                </div>
            </div>
        </section>

        <div className="position-relative">
            <div className="shape overflow-hidden text-white">
                <svg viewBox="0 0 2880 48" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M0 48H1437.5H2880V0H2160C1442.5 52 720 0 720 0H0V48Z" fill="currentColor"></path>
                </svg>
            </div>
        </div>

      {/* New Section for Content */}
      <section className="container mt-3" style={{ marginBottom: '50px' }}> {/* Add margin-bottom here */}
        {/* Heading for Mining Location */}
        <h2 style={{ textAlign: 'center', marginBottom: '20px' }}>
          We Provide Mining At: <span style={{ color: "#326bbe" }}>Chhattisgarh & Madhya Pradesh</span>
        </h2>

        <div className="row align-items-center">
          {/* Left Side for Image */}
          <div className="col-lg-6 col-md-12">
            <img 
              src={limestone} 
              alt="BMI Construction Project" 
              className="img-fluid rounded" // Added rounded corners for style
              style={{
                width: '100%',
                height: '450px',
                boxShadow: '0 4px 8px rgba(0, 0, 0, 0.1)', // Shadow effect
                borderRadius: '10px', // Optional rounded corners
              }}
            />
          </div>

          {/* Right Side for Content */}
          <div className="col-lg-6 col-md-12 d-flex justify-content-center align-items-center">
            <div style={{ padding: '40px', marginTop: '20px' }}> {/* Add marginTop here */}
              <h3>We facilitate top-quality <span style={{ color: "#326bbe" }}>Limestone</span> mining services</h3><br />
              <p style={{ fontSize: '1.3rem', lineHeight: '1.5', color: '#333', marginBottom: '20px' }}>
              "At BMI Construction, we offer specialized limestone mining services in Madhya Pradesh, characterized by our precision and expertise. With years of experience in the industry, we utilize innovative methods and craftsmanship to deliver outstanding results. Our commitment to excellence ensures that we meet your mining needs while making a positive difference in the environment and the communities we operate in."              </p>
              <p style={{ fontSize: '1.2rem', lineHeight: '1.3', color: '#333' }}>
                Explore our services today and see how we can fulfill your mineral mining needs!
              </p>
            </div>
          </div>
        </div>
      </section>

      <section className="container mt-3" style={{ marginBottom: '50px' }}> {/* Add margin-bottom here */}
        {/* Heading for Bauxite Ore Mining */}
      

        <div className="row align-items-center order-last order-lg-first">
          {/* Right Side for Content */}
          <div className="col-lg-6 col-md-12 d-flex justify-content-center align-items-center">
            <div style={{ padding: '40px', marginTop: '20px' }}> {/* Add marginTop here */}
              <h3>Delivering High-Quality <span style={{ color: "#326bbe" }}>bauxite ore</span>  Mining with Environmental Responsibility</h3><br />
              <p style={{ fontSize: '1.3rem', lineHeight: '1.5', color: '#333', marginBottom: '20px' }}>
              "At BMI Construction, we take pride in our comprehensive bauxite ore mining services in Chhattisgarh, marked by our precision and expertise. Our extensive industry experience enables us to efficiently extract high-quality bauxite, essential for aluminum production. By implementing advanced mining technologies and environmentally responsible practices, we ensure optimal extraction while minimizing ecological harm. Our dedicated team is focused on upholding the highest safety standards and fostering positive relationships with local communities."
              </p>
            </div>
          </div>

          {/* Left Side for Image */}
          <div className="col-lg-6 col-md-12 order-first order-lg-last">
            <img 
              src={bauxite_img} 
              alt="BMI Construction Project" 
              className="img-fluid rounded" // Added rounded corners for style
              style={{
                width: '100%',
                height: '480px',
                boxShadow: '0 4px 8px rgba(0, 0, 0, 0.1)', // Shadow effect
                borderRadius: '10px', // Optional rounded corners
              }}
            />
          </div>
        </div>
      </section>
      <section className="container mt-3" style={{ marginBottom: '50px' }}> {/* Add margin-bottom here */}
        {/* Heading for Mining Location */}
       

        <div className="row align-items-center">
          {/* Left Side for Image */}
          <div className="col-lg-6 col-md-12">
            <img 
              src={coal_img} 
              alt="BMI Construction Project" 
              className="img-fluid rounded" // Added rounded corners for style
              style={{
                width: '100%',
                height: '450px',
                boxShadow: '0 4px 8px rgba(0, 0, 0, 0.1)', // Shadow effect
                borderRadius: '10px', // Optional rounded corners
              }}
            />
          </div>

          {/* Right Side for Content */}
          <div className="col-lg-6 col-md-12 d-flex justify-content-center align-items-center">
            <div style={{ padding: '40px', marginTop: '20px' }}> {/* Add marginTop here */}
              <h3>We facilitate top-quality <span style={{ color: "#326bbe" }}>Coal</span> mining services</h3><br />
              <p style={{ fontSize: '1.3rem', lineHeight: '1.5', color: '#333', marginBottom: '20px' }}>
              
              With a focus on excellence, BMI Construction offers comprehensive raw coal mining services across Madhya Pradesh and Chhattisgarh that stand out in the industry. Our team of experts leverages years of experience to deliver safe, efficient, and environmentally responsible mining solutions. We are committed to supporting local communities and driving sustainable development through our mining practices            </p></div>
          </div>
        </div>
      </section>
      <section className="container mt-3" style={{ marginBottom: '50px' }}> {/* Add margin-bottom here */}
        {/* Heading for Bauxite Ore Mining */}
      

        <div className="row align-items-center order-last order-lg-first">
          {/* Right Side for Content */}
          <div className="col-lg-6 col-md-12 d-flex justify-content-center align-items-center">
            <div style={{ padding: '40px', marginTop: '20px' }}> {/* Add marginTop here */}
              <h3>We offer premium <span style={{ color: "#326bbe" }}>Iron ore</span> mining services</h3><br />
              <p style={{ fontSize: '1.3rem', lineHeight: '1.5', color: '#333', marginBottom: '20px' }}>
              "With a strong emphasis on quality and sustainability, BMI Construction provides comprehensive iron ore mining services in Chhattisgarh that meet the highest industry standards. Our skilled professionals utilize the latest technologies to ensure efficient extraction while being mindful of our environmental responsibilities. We are dedicated to creating a positive impact on local communities through our responsible mining practices."    </p>        </div>
          
          </div>

          {/* Left Side for Image */}
          <div className="col-lg-6 col-md-12 order-first order-lg-last">
            <img 
              src={iron_img} 
              alt="BMI Construction Project" 
              className="img-fluid rounded" // Added rounded corners for style
              style={{
                width: '100%',
                height: '480px',
                boxShadow: '0 4px 8px rgba(0, 0, 0, 0.1)', // Shadow effect
                borderRadius: '10px', // Optional rounded corners
              }}
            />
          </div>
        </div>
      </section>   <section className="container mt-3" style={{ marginBottom: '50px' }}> {/* Add margin-bottom here */}
        {/* Heading for Mining Location */}
       

        <div className="row align-items-center">
          {/* Left Side for Image */}
          <div className="col-lg-6 col-md-12">
            <img 
              src={stone_img} 
              alt="BMI Construction Project" 
              className="img-fluid rounded" // Added rounded corners for style
              style={{
                width: '100%',
                height: '450px',
                boxShadow: '0 4px 8px rgba(0, 0, 0, 0.1)', // Shadow effect
                borderRadius: '10px', // Optional rounded corners
              }}
            />
          </div>

          {/* Right Side for Content */}
          <div className="col-lg-6 col-md-12 d-flex justify-content-center align-items-center">
            <div style={{ padding: '40px', marginTop: '20px' }}> {/* Add marginTop here */}
              <h3>Innovative <span style={{ color: "#326bbe" }}>Stone</span>  Mining Services with a Focus on Sustainability </h3><br />
              <p style={{ fontSize: '1.3rem', lineHeight: '1.5', color: '#333', marginBottom: '20px' }}>
              BMI Construction is committed to delivering high-quality crushed stone mining products in Chhattisgarh and MP that adhere to the highest industry standards. Our expert team leverages advanced technologies to ensure efficient processing while prioritizing sustainability and environmental stewardship. We strive to make a positive difference in local communities through our responsible mining practices.    </p>
             
            </div>
          </div>
        </div>
      </section>
      <section className="container mt-3" style={{ marginBottom: '50px' }}> {/* Add margin-bottom here */}
        {/* Heading for Bauxite Ore Mining */}
      

        <div className="row align-items-center order-last order-lg-first">
          {/* Right Side for Content */}
          <div className="col-lg-6 col-md-12 d-flex justify-content-center align-items-center">
            <div style={{ padding: '40px', marginTop: '20px' }}> {/* Add marginTop here */}
              <h3>Reliable and Sustainable  <span style={{ color: "#326bbe" }}>Sand</span> mining services</h3><br />
              <p style={{ fontSize: '1.3rem', lineHeight: '1.5', color: '#333', marginBottom: '20px' }}>
              At BMI Construction, we specialize in delivering comprehensive sand mining services with precision and expertise in Chhattisgarh and MP. Our extensive experience in the mining sector enables us to effectively extract high-quality sand, which is essential for construction and various industrial applications. We employ advanced mining techniques and environmentally responsible practices to ensure efficient extraction while minimizing our ecological footprint. Our team is dedicated to maintaining the highest safety standards and fostering positive relationships with local communities
              </p>
            </div>
          </div>

          {/* Left Side for Image */}
          <div className="col-lg-6 col-md-12 order-first order-lg-last">
            <img 
              src={sand_img} 
              alt="BMI Construction Project" 
              className="img-fluid rounded" // Added rounded corners for style
              style={{
                width: '100%',
                height: '480px',
                boxShadow: '0 4px 8px rgba(0, 0, 0, 0.1)', // Shadow effect
                borderRadius: '10px', // Optional rounded corners
              }}
            />
          </div>
        </div>
        <CarouselSection/>
      </section>
      <Footer/>
    </>
  );
}

export default Mining;