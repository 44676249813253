import React, { useState } from "react";
import { Link } from "react-router-dom";

import Navbar from "../components/navbar";
import bg3 from "../assect/images/bg/05.jpg"

import {FiArrowRight} from '../assect/icons/vander'
import Footer from "../components/footer";

export default function Terms(){

    return(
        <>
        <Navbar navClass="defaultscroll sticky" logolight={true} menuClass = "navigation-menu nav-left nav-light"/>
        <section className="bg-half-170 d-table w-100" style={{backgroundImage:`url(${bg3})`}}>
            <div className="bg-overlay bg-gradient-overlay-2"></div>
            <div className="container">
                <div className="row mt-5 justify-content-center">
                    <div className="col-12">
                        <div className="title-heading text-center">
                            <h5 className="heading fw-semibold mb-0 sub-heading text-white title-dark">Terms & Conditions</h5>
                        </div>
                    </div>
                </div>
                <div className="position-middle-bottom">
                    <nav aria-label="breadcrumb" className="d-block">
                        <ul className="breadcrumb breadcrumb-muted mb-0 p-0">
                            <li className="breadcrumb-item"><Link to="/">BMI Real Estate</Link></li>
                            <li className="breadcrumb-item active" aria-current="page">Terms & Conditions</li>
                        </ul>
                    </nav>
                </div>
            </div>
        </section>
        <div className="position-relative">
            <div className="shape overflow-hidden text-white">
                <svg viewBox="0 0 2880 48" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M0 48H1437.5H2880V0H2160C1442.5 52 720 0 720 0H0V48Z" fill="currentColor"></path>
                </svg>
            </div>
        </div>
        <section className="section">
            <div className="container">
                <div className="row justify-content-center">
                    <div className="col-lg-9">
                        <div className="card shadow border-0 rounded-3">
                            <div className="card-body">
                                <h5 className="card-title">Terms & Conditions :</h5>

                                       <p>
                                        This website ("Website") & Applications are property of and
                                        is provided and governed by Birsa Munda Infratech (BMI-Real Estate), having its
                                        registered office at 213, Apollo Premier, Vijay Nagar square, Indore (M.P), INDIA, 452001.
                                        </p>
                                        <p>
                                        By using the Website &/or Application of Birsa Munda Infratech (BMI-Real Estate),
                                        for any purpose and in any manner including general
                                        browsing, response/feedback, availing of services or
                                        downloading content, it is presumed that you have read and
                                        understood and have agreed to abide by and be governed by
                                        the terms and conditions set forth herein read along with
                                        any other terms and conditions governing the use of the
                                        Website including linked sites or any applications.
                                        </p>
                                        <p>
                                        If you do not agree to be governed by the Terms and
                                        Conditions, you are requested not to use the Website &/or
                                        Application of Birsa Munda Infratech (BMI-Real Estate).
                                        </p>
                                        <p>
                                        The Website &/or Application of Birsa Munda Infratech (BMI-Real Estate) is solely
                                        for your personal non-commercial use and you must ensure
                                        that your use of the Website is exclusively for this
                                        purpose.
                                        </p>
                                        <p>
                                        You shall use the website only for lawful purposes and in
                                        accordance with the Website Terms and Conditions. You shall
                                        be completely and solely responsible and liable for any
                                        charges, taxes, duties, fees and any other charges that
                                        arise in connection with your use of the Website &/or
                                        Application of Birsa Munda Infratech (BMI-Real Estate).
                                        </p>
                                        <p>
                                        Your use of the Website &/or Application of Birsa Munda Infratech (BMI-Real Estate) 
                                        is subject to the "Privacy Policy" available on
                                        the Website. Please review the same.
                                        </p>                 

                                
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
        <Footer/>
        </>
    )
}