import React from "react";
import { Link } from "react-router-dom";

import { FiHexagon, FiHome, FiBriefcase, FiKey } from "../assect/icons/vander";

export default function AboutUs() {
  const aboutData = [
    {
      icon: FiHome,
      title: "Evaluate Property",
      desc: "We focus on high-growth areas like Indore-Ujjain Road, Super Corridor, IIM Pithampur Road, AB Road Mhow, and others chosen for their strategic value and market demand. These locations offer strong potential for development, combining connectivity with investment opportunities to meet the evolving needs of residents and businesses alike.",
    },
    {
      icon: FiBriefcase,
      title: "Meet Us",
      desc: "In the meeting with the agent for BMI, we’ll cover the property overview, including location, size, and condition. We’ll discuss current market trends, pricing, and demand in the area, as well as potential development opportunities. It’s crucial to confirm the legal status, ensuring all titles and zoning are clear. Lastly, we’ll evaluate the pricing and long-term growth potential of the area.",
    },
    {
      icon: FiKey,
      title: "Close the Deal",
      desc: "To close the deal in real estate, we’ll finalize negotiations on price and terms, review and sign the purchase agreement, and conduct a final property inspection. We’ll also secure financing and complete any necessary paperwork to ensure everything is in order for a smooth transaction.",
    },
  ];
  return (
    <>
      <div className="row justify-content-center">
        <div className="col">
          <div className="section-title text-center mb-4 pb-2">
            <h4 className="title mb-3">How It Works</h4>
            <p className="text-muted para-desc mb-0 mx-auto">
              A great platform to buy properties directly from us, ensuring a
              transparent and hassle-free process.
            </p>
          </div>
        </div>
      </div>

      <div className="row g-4 mt-0">
        {aboutData.map((item, index) => {
          const Icon = item.icon;
          return (
            <div className="col-md-4" key={index}>
              <div className="position-relative features text-center mx-lg-4 px-md-1">
                <div className="feature-icon position-relative overflow-hidden d-flex justify-content-center">
                  <FiHexagon className="hexagon" />
                  <div className="position-absolute top-50 start-50 translate-middle">
                    <Icon className="fea icon-m-md text-primary" />
                  </div>
                </div>

                <div className="mt-4">
                  <Link to="#" className="fw-medium title text-dark fs-5">
                    {item.title}
                  </Link>
                  <p className="text-muted mt-3 mb-0">{item.desc}</p>
                </div>
              </div>
            </div>
          );
        })}
      </div>
    </>
  );
}
