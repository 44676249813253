import React,{useState} from "react";
import { Link,useParams } from "react-router-dom";

import image1 from '../../assect/images/property/single/1.jpg'
import image2 from '../../assect/images/property/single/2.jpg'
import image3 from '../../assect/images/property/single/3.jpg'
import image4 from '../../assect/images/property/single/4.jpg'
import image5 from '../../assect/images/property/single/5.jpg'

import Navbar from "../../components/navbar";
import ProprtySlider from "../../components/propertySlider";

import { propertyData } from "../../data/data";

import Lightbox from 'react-18-image-lightbox'
import "../../../node_modules/react-18-image-lightbox/style.css"
import Footer from "../../components/footer";


export default function PropertyDetails(){

    const params = useParams()
    const id = params.id
    const data = propertyData.find((item) => item.id === parseInt(id))

    const [currentImageIndex, setCurrentImageIndex] = useState(0);
    const [open, setIsOpen] = useState(false);
    const images =[image1,image2,image3,image4,image5]

   const handleMovePrev = () => {
        setCurrentImageIndex((prevIndex) => (prevIndex + images.length - 1) % images.length);
    };

    const handleMoveNext = () => {
        setCurrentImageIndex((prevIndex) => (prevIndex + 1) % images.length);
    };

    const handleImageClick = (index) => {
        setCurrentImageIndex(index);
        setIsOpen(true);
    };

    const currentImage = images[currentImageIndex];
    return(
        <>
        <Navbar navClass="defaultscroll sticky" menuClass = "navigation-menu nav-left"/>
        <section className="section mt-5 pt-4">
            <div className="container-fluid mt-2">
                <div className="row g-2">
                    <div className="col-md-6">
                        <Link to="#" onClick={() => handleImageClick(0)} className="lightbox" title="">
                            <img src={image1} className="img-fluid rounded-3 shadow" alt=""/>
                        </Link>
                    </div>

                    <div className="col-md-6">
                        <div className="row g-2">
                            <div className="col-6">
                                <Link to="#" onClick={() => handleImageClick(1)} className="lightbox" title="">
                                    <img src={image2} className="img-fluid rounded-3 shadow" alt=""/>
                                </Link>
                            </div>

                            <div className="col-6">
                                <Link to="#" onClick={() => handleImageClick(2)} className="lightbox" title="">
                                    <img src={image3} className="img-fluid rounded-3 shadow" alt=""/>
                                </Link>
                            </div>

                            <div className="col-6">
                                <Link to="#" onClick={() => handleImageClick(3)} className="lightbox" title="">
                                    <img src={image4} className="img-fluid rounded-3 shadow" alt=""/>
                                </Link>
                            </div>

                            <div className="col-6"> 
                                <Link to="#" onClick={() => handleImageClick(4)} className="lightbox" title="">
                                    <img src={image5} className="img-fluid rounded-3 shadow" alt=""/>
                                </Link>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div className="container mt-100 mt-60">
                <div className="row g-4">
                    <div className="col-lg-8 col-md-7 col-12">
                        <div className="section-title">
                            <h4 className="title mb-0">{data?.title ? data?.title : "10765 Hillshire Ave, Baton Rouge, LA 70810, USA"}</h4>
                            
                            <ul className="list-unstyled mb-0 py-3">
                                <li className="list-inline-item">
                                    <span className="d-flex align-items-center me-4">
                                        {/* <i className="mdi mdi-arrow-expand-all fs-4 me-2 text-primary"></i> */}
                                        {/* <i className="mdi mdi-flash fs-4 me-2 text-warning"></i> */}
                                        <i className="mdi mdi-flash fs-4 me-2 text-primary"></i>

                                        <span className="text-muted fs-5">24/7 Electricity</span>
                                    </span>
                                </li>

                                <li className="list-inline-item">
                                    <span className="d-flex align-items-center me-4">
                                        {/* <i className="mdi mdi-bed fs-4 me-2 text-primary"></i> */}
                                        <i className="mdi mdi-water fs-4 me-2 text-primary"></i>

                                        <span className="text-muted fs-5">Underground Water</span>
                                    </span>
                                </li>

                                <li className="list-inline-item">
                                    <span className="d-flex align-items-center">
                                        {/* <i className="mdi mdi-shower fs-4 me-2 text-primary"></i> */}
                                        <i className="mdi mdi-shield-account fs-4 me-2 text-primary"></i>

                                        <span className="text-muted fs-5">24/7 Security Guard</span>
                                    </span>
                                </li>
                            </ul>

                            <p className="text-muted">{data.desc}</p>
                            <h4 className="title mb-0">Ammenties</h4>
        
      <ul className="ms-3">
        <li >
          <strong>{data.a}</strong> {data.a1}
        </li>
        <li >
          <strong>{data.b}</strong> {data.b1}
        </li>
        <li >
          <strong>{data.c}</strong> {data.c1}
        </li>
        <li >
          <strong>{data.d}</strong> {data.d1}
        </li>
        <li >
          <strong>{data.e}</strong> {data.e1}
        </li>
        <li >
          <strong>{data.f}</strong> {data.f1}
        </li>
        <li >
          <strong>{data.g}</strong> {data.g1}
        </li>
        <li >
          <strong>{data.h}</strong>{data.h1}
        </li>
      </ul>
                            <div className="card map border-0">
                                <div className="card-body p-0">
                                    <iframe src={data.location} className="rounded-3" style={{border:'0'}} title="Townter" allowFullScreen></iframe>
                                </div>
                            </div>
                        </div>
                    </div>

                    {/* <div className="col-lg-4 col-md-5 col-12">
                        <div className="rounded-3 shadow bg-white sticky-bar p-4">
                            <h5 className="mb-3">Price:</h5>

                            <div className="d-flex align-items-center justify-content-between">
                                <h5 className="mb-0">$ 45,231</h5>
                                <span className="badge bg-primary">For Sale</span>
                            </div>

                            <div className="">
                                <div className="d-flex align-items-center justify-content-between mt-2">
                                    <span className="small text-muted">Days on BMI Real Estate</span>
                                    <span className="small">124 Days</span>
                                </div>

                                <div className="d-flex align-items-center justify-content-between mt-2">
                                    <span className="small text-muted">Price per sq ft</span>
                                    <span className="small">$ 186</span>
                                </div>

                                <div className="d-flex align-items-center justify-content-between mt-2">
                                    <span className="small text-muted">Monthly Payment (estimate)</span>
                                    <span className="small">$ 1497/Monthly</span>
                                </div>
                            </div>

                            <div className="d-flex mt-3">
                                <Link to="#" className="btn btn-primary w-100 me-2">Book Now</Link>
                                <Link to="#" className="btn btn-primary w-100">Offer now</Link>
                            </div>
                        </div>
                    </div> */}
                </div>
            </div>

            <div className="container mt-100 mt-60">
                <div className="row justify-content-center">
                    <div className="col">
                        <div className="section-title text-center mb-4 pb-2">
                            <h4 className="title mb-3">Related Properties</h4>
                            <p className="text-muted para-desc mb-0 mx-auto">A great platform to buy, sell and rent your properties without any agent or commisions.</p>
                        </div>
                    </div>
                </div>

                <ProprtySlider/>
            </div>
        </section>
        <Footer/>
        {open && (
                <Lightbox
                    mainSrc={currentImage}
                    prevSrc={images[(currentImageIndex + images.length - 1) % images.length]}
                    nextSrc={images[(currentImageIndex + 1) % images.length]}

                    onCloseRequest={() => setIsOpen(false)}
                    onMovePrevRequest={handleMovePrev}
                    onMoveNextRequest={handleMoveNext}
                />
            )}
        </>
    )
}