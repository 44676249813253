import React, { useState } from "react";
// import { Link as Link2 } from 'react-scroll';
import Navbar from "../components/navbar";
import bg06 from "../assect/images/bg/06.jpg"; 
import Footer from "../components/footer";

export default function Faqs() {
    const [activeIndex, setActiveIndex] = useState(null); 

    const accordionData = [
        {
            id: 1,
            title: 'Why should I choose BMI Real Estate?',
            desc: 'Founded by experienced industry leaders, BMI Real Estate has successfully assisted numerous clients in finding their ideal properties. Our dedicated team of professionals is committed to making your home buying process seamless and stress-free.'
        },
        {
            id: 2,
            title: 'Why do I need a real estate agent?',
            desc: 'You need a real estate agent because they provide expert guidance through the complex buying or selling process, ensuring that all paperwork is handled correctly and efficiently. Their negotiation skills help you secure the best deal while accessing a broader range of listings, including exclusive properties. Ultimately, an agent saves you time and reduces stress, making the entire experience smoother and more successful.'
        },
        {
            id: 3,
            title: 'What is the process for purchasing a home through your website?',
            desc:'The process for purchasing a home through our website involves a few simple steps: First, browse our listings and select a property that meets your criteria. Next, submit an inquiry to express your interest, and our team will assist you in scheduling a site visit. Once you decide to move forward, we’ll handle all legal paperwork, arrange financing if needed, and guide you through the closing process to ensure a seamless transaction.'
        },
        {
            id: 4,
            title: 'How can I ensure that I’m getting a fair price for a property?',
            desc: "To ensure you're getting a fair price for a property, research recent sales of comparable homes in the area to understand market trends. You can also consult with a real estate agent for their expert opinion on pricing and property value. Additionally, consider getting a professional appraisal to provide an objective evaluation of the property's worth."
        },
        {
            id:5,
            title:' What is the selling/buying process like ?',
            desc:"The buying process typically begins with defining your budget and criteria, followed by browsing listings and scheduling property viewings. Once you find a suitable home, you'll make an offer, negotiate terms, and, if accepted, proceed with inspections and securing financing. For selling, you'll prepare your home for listing, set a competitive price, and work with your agent to market the property, negotiate offers, and finalize the sale."
        },
        {
            id:6,
            title:'What kind of properties are you providing?',
            desc:"We provide a diverse range of properties, including residential options like apartments, villas, and plots, as well as commercial and industrial properties. Our listings feature newly constructed homes, resale properties, under-construction developments, and upcoming projects, catering to various preferences and budgets across both residential and commercial sectors."
        },
        ,{
            id:7,
            title:'What financing options are available for first-time homebuyers?',
            desc:"First-time homebuyers have access to several financing options, including traditional home loans from banks and financial institutions with competitive interest rates. Additionally, government programs like the Pradhan Mantri Awas Yojana (PMAY) offer grants and subsidies to make homeownership more affordable. Many lenders also provide specialized programs for first-time buyers, featuring lower down payments and flexible repayment terms."
        },
        {
            id:8,
            title:'How do I know if a property has any outstanding legal issues?    ',
            desc:"To check if a property has any legal issues, start by getting an Encumbrance Certificate, which shows if there are any loans or claims on it. You can also do a title search at the local land office to see the ownership history and any disputes. Hiring a real estate lawyer can help you look over important documents and ensure the property is clear of any legal problems."
        },
        {
            id:9,
            title:'What documents are required for property registration',
            desc:"For property registration in India, you typically need the Sale Deed, identity proof (such as an Aadhar card or passport), address proof, and a PAN card for both the buyer and seller. An Encumbrance Certificate is required to confirm that there are no legal dues, along with property tax receipts and previous title documents. Consulting a legal expert can help ensure you have all the necessary documentation."
        },
        {
            id:10,
            title:"Who should I contact in case I face any issues?",
            desc:"If you encounter any issues, please reach out to our dedicated customer care service team, available seven days a week from 8:30 AM to 7:30 PM. You can contact them at 9685911623/6262137006. We're here to assist you!"
        }
    ];

    return (
        <>
            <Navbar navClass="defaultscroll sticky" logolight={true} menuClass="navigation-menu nav-left nav-light" />
            <section className="bg-half-170 d-table w-100" style={{ backgroundImage: `url(${bg06})` }}>
                <div className="bg-overlay bg-gradient-overlay-2"></div>
                <div className="container">
                    <div className="row mt-5 justify-content-center">
                        <div className="col-12">
                            <div className="title-heading text-center">
                            <p className="text-white-50 para-desc mx-auto mb-0">Your Questions, Our Answers</p>
                            <h5 className="heading fw-semibold mb-0 sub-heading text-white title-dark">Everything You Need to Know About Dealing with BMI.</h5>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            <div className="position-relative">
                <div className="shape overflow-hidden text-white">
                    <svg viewBox="0 0 2880 48" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M0 48H1437.5H2880V0H2160C1442.5 52 720 0 720 0H0V48Z" fill="currentColor"></path>
                    </svg>
                </div>
            </div>
            <section className="section">
                <div className="container">
                    <div className="col-lg-8 col-md-7 col-12 mx-auto"> 
                        <div id="tech">
                            <div className="section-title text-center"> 
                                <h4 className="mb-4">Frequently Asked Questions</h4>
                            </div>
                            <div className="accordion mt-4 pt-2" id="buyingquestion">
                                {accordionData.map((item) => {
                                    return (
                                        <div className="accordion-item rounded-3 mb-3" key={item.id}>
                                            <h2 className="accordion-header" id={`heading${item.id}`}>
                                                <button
                                                    className={`accordion-button border-0 bg-light ${activeIndex === item.id ? "active" : ""}`}
                                                    onClick={() => setActiveIndex(activeIndex === item.id ? null : item.id)} // Toggle active index
                                                >
                                                    {item.title}
                                                </button>
                                            </h2>
                                            {activeIndex === item.id && (
                                                <div id={`collapse${item.id}`} className="accordion-collapse border-0">
                                                    <div className="accordion-body text-muted">
                                                        {item.desc}
                                                    </div>
                                                </div>
                                            )}
                                        </div>
                                    );
                                })}
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            <Footer />
        </>
    );
}