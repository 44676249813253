import React from "react";
import { Link } from "react-router-dom";
import Navbar from "../components/navbar";
import bg3 from "../assect/images/bg/05.jpg";
import { FiArrowRight } from "../assect/icons/vander";
import Footer from "../components/footer";

export default function Privacy() {
  return (
    <>
      <Navbar
        navClassNamclassName="defaultscroll sticky"
        logolight={true}
        menuClass="navigation-menu nav-left nav-light"
      />
      <section
        className="bg-half-170 d-table w-100"
        style={{ backgroundImage: `url(${bg3})` }}
      >
        <div className="bg-overlay bg-gradient-overlay-2"></div>
        <div className="container">
          <div className="row mt-5 justify-content-center">
            <div className="col-12">
              <div className="title-heading text-center">
                <h5 className="heading fw-semibold mb-0 sub-heading text-white title-dark">
                  Privacy Policy
                </h5>
              </div>
            </div>
          </div>
          <div className="position-middle-bottom">
            <nav aria-label="breadcrumb" className="d-block">
              <ul className="breadcrumb breadcrumb-muted mb-0 p-0">
                <li className="breadcrumb-item">
                  <Link to="/">BMI Real Estate</Link>
                </li>
                <li className="breadcrumb-item active" aria-current="page">
                  Privacy
                </li>
              </ul>
            </nav>
          </div>
        </div>
      </section>
      <div className="position-relative">
        <div className="shape overflow-hidden text-white">
          <svg
            viewBox="0 0 2880 48"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M0 48H1437.5H2880V0H2160C1442.5 52 720 0 720 0H0V48Z"
              fill="currentColor"
            ></path>
          </svg>
        </div>
      </div>
      <section className="section">
        <div className="container">
          <div className="row justify-content-center">
            <div className="col-lg-9">
              <div className="card shadow rounded-3 border-0">
                <div className="card-body">
                  <h5 className="card-title">Privacy Policy :</h5>

                  <ul className="list-unstyled text-muted">
                    <li className="mt-2">
                      <FiArrowRight className="fea icon-sm me-2" />
                      The user/s may be required to provide personal information
                      in any of the forms on the Websites &/or Application of
                      Birsa Munda Infratech (BMI-Real Estate). By agreeing to
                      the terms, the user/s agree to provide true and complete
                      information.
                    </li>
                    <li className="mt-2">
                      <FiArrowRight className="fea icon-sm me-2" />
                      We do not partner with or have any relationship with ad
                      server companies.
                    </li>
                    <li className="mt-2">
                      <FiArrowRight className="fea icon-sm me-2" />
                      By submitting your contact number, you consent to being
                      contacted for special offers, schemes, and upcoming
                      projects via SMS, telecalls, email, etc.
                    </li>
                    <li className="mt-2">
                      <FiArrowRight className="fea icon-sm me-2" />
                      The Websites &/or Application are available for access in
                      India only. The terms and conditions shall be governed by
                      Indian laws, with exclusive jurisdiction in Indore (Madhya Pradesh).  
                    </li>
                    <li className="mt-2">
                      <FiArrowRight className="fea icon-sm me-2" />
                      The user/s shall only use the Websites for lawful purposes
                      in accordance with the terms outlined by Birsa Munda
                      Infratech (BMI-Real Estate).
                    </li>
                    <li className="mt-2">
                      <FiArrowRight className="fea icon-sm me-2" />
                      Some images on the Websites &/or Application may differ
                      from the actual product and are used for recreational
                      purposes. Please verify before drawing conclusions.
                    </li>
                    <li className="mt-2">
                      <FiArrowRight className="fea icon-sm me-2" />
                      All project details, layouts, and features are subject to
                      change by Birsa Munda Infratech (BMI-Real Estate) or as
                      required by regulatory authorities without prior notice.
                    </li>
                    <li className="mt-2">
                      <FiArrowRight className="fea icon-sm me-2" />
                      Pricing information is for reference only and may change.
                      Final pricing will be confirmed by our sales team based on
                      availability and offers.
                    </li>
                    <li className="mt-2">
                      <FiArrowRight className="fea icon-sm me-2" />
                      Property bookings and sales are subject to document
                      verification and legal formalities. Confirmed booking
                      doesn't guarantee ownership until all processes are
                      completed.
                    </li>
                    <li className="mt-2">
                      <FiArrowRight className="fea icon-sm me-2" />
                      Users are advised to verify legal, financial, and other
                      aspects of properties before entering into agreements with
                      Birsa Munda Infratech (BMI-Real Estate). The company is
                      not liable for any discrepancies.
                    </li>
                  </ul>

                  <Link to="#" className="btn btn-primary">
                    Print
                  </Link>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <Footer />
    </>
  );
}
