import React, { useState } from "react";
import { Link } from "react-router-dom";

import Navbar from "../components/navbar";
import bg3 from "../assect/images/bg/03.jpg"

import { FiArrowRight } from '../assect/icons/vander'
import Footer from "../components/footer";
import '../assect/scss/service.css'
import home from '../assect/icons/Home.png'
import plot from '../assect/icons/Plots.png'
import shops from '../assect/icons/Shops.png'
import construction from '../assect/icons/Construction.png'
import office from '../assect/icons/Office_spaces.png'
import mining from '../assect/icons/Mining.png'


export default function Services() {
    const services = [
        { id: 1, name: 'Residential / Commercial Plots',icon:plot},  // Use appropriate icons instead of emojis
        { id: 2, name: 'Flats / Apartments / Bunglow',icon:home },
        { id: 3, name: 'Shops / Showroom',icon:shops },
        { id: 4, name: 'Construction and Development',icon:construction },
        { id: 5, name: 'Office Spaces',icon:office},
        { id: 6, name: 'Sustainable mining',icon:mining },

    ];

    return (
        <>
            <Navbar navClass="defaultscroll sticky" logolight={true} menuClass="navigation-menu nav-left nav-light" />
            <section className="bg-half-170 d-table w-100" style={{ backgroundImage:` url(${bg3})` }}>

                <div className="bg-overlay bg-gradient-overlay-2"></div>
                <div className="container">
                    <div className="row mt-5 justify-content-center">
                        <div className="col-12">
                            <div className="title-heading text-center">
                                <h5 className="heading fw-semibold mb-0 sub-heading text-white title-dark">What We Offer : Our Services</h5>
                            </div>
                        </div>
                    </div>
                    <div className="position-middle-bottom">
                        <nav aria-label="breadcrumb" className="d-block">
                            <ul className="breadcrumb breadcrumb-muted mb-0 p-0">
                                <li className="breadcrumb-item"><Link to="/">BMI Real Estate</Link></li>
                                <li className="breadcrumb-item active" aria-current="page">Services</li>
                            </ul>
                        </nav>
                    </div>
                </div>
            </section>
            <div className="position-relative">
                <div className="shape overflow-hidden text-white">
                    <svg viewBox="0 0 2880 48" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M0 48H1437.5H2880V0H2160C1442.5 52 720 0 720 0H0V48Z" fill="currentColor"></path>
                    </svg>
                </div>
            </div>
            <section className="section">
                <div className="container">
                    <div className="row justify-content-center">
                        <div className="col-lg-9">
                            <div className="card shadow border-0 rounded-3">
                                <div className="card-body">
                                    <h5 style={{marginBottom:"20px"}} className="card-title">We Offer You : </h5>



                                    
                                    <p>
                                        At BMI, we provide a wide range of real estate and mining services designed to meet the diverse needs of our clients. Our real estate offerings include residential plots, modern flats, and custom-built homes in prime locations, as well as commercial properties that cater to businesses of all sizes. In addition, we offer expert consultation, land development, and property management services to ensure seamless transactions and long-term value. In the mining sector, we focus on sustainable practices that balance growth and environmental responsibility. With a client-first approach, we are committed to delivering excellence in every project.


                                    </p>
                                    <p>We offer you best property at prime location like : Indore-Ujjain Road, Super Corridor, IIM Pithampur Road, Khandwa Road, A.B. Road Mahu, Behind Jhalaria Hotel Grand Sheraton, MR 11, A.B. Road SAGE University, Dhar Road, CAT Road Rau Rangwasa, Bicholi Bypass.</p>
                                    <div className="services-grid">
                                        {services.map((service) => (
                                            <div key={service.id} className="service-item">
                                                <span className="service-icon"><img src={service.icon}/></span>
                                                <span className="service-name">{service.name}</span>
                                            </div>
                                        ))}
                                    </div>



                                </div>
                            </div>
                        </div>

                    </div>

                </div>

            </section>
            <Footer />
        </>
    )
}