import React from 'react';
import 'bootstrap/dist/css/bootstrap.min.css'; // Ensure you import Bootstrap CSS
import 'bootstrap/dist/js/bootstrap.bundle.min.js'; // Ensure you import Bootstrap JS
import img1 from '../assect/images/build3.jpeg';
import img2 from '../assect/images/build4.jpeg';
import img3 from '../assect/images/build5.jpeg';
import img4 from '../assect/images/img1.jpeg';
import img5 from '../assect/images/img2.jpeg';
import img6 from '../assect/images/img3.jpeg';
import img7 from '../assect/images/img4.jpeg';
import img8 from '../assect/images/img5.jpeg';

function CarouselSection() {
    return (
        <section className="container mt-5" style={{ marginBottom: "50px" }}>
            <div id="carouselExample" className="carousel slide" data-bs-ride="carousel" data-bs-interval="3000">
                <div className="carousel-inner">
                    {/* First Group - Mobile shows 1 image, Desktop shows 3 images */}
                    <div className="carousel-item active">
                        <div className="row">
                            <div className="col-12 col-md-4">
                                <img src={img1} className="d-block w-100" alt="First slide" style={{ height: '400px', objectFit: 'cover' }} />
                            </div>
                            <div className="col-12 col-md-4">
                                <img src={img2} className="d-block w-100" alt="Second slide" style={{ height: '400px', objectFit: 'cover' }} />
                            </div>
                            <div className="col-12 col-md-4">
                                <img src={img3} className="d-block w-100" alt="Third slide" style={{ height: '400px', objectFit: 'cover' }} />
                            </div>
                        </div>
                    </div>
                    {/* Second Group - Mobile shows 1 image, Desktop shows 3 images */}
                    <div className="carousel-item">
                        <div className="row">
                            <div className="col-12 col-md-4">
                                <img src={img4} className="d-block w-100" alt="Fourth slide" style={{ height: '400px', objectFit: 'cover' }} />
                            </div>
                            <div className="col-12 col-md-4">
                                <img src={img5} className="d-block w-100" alt="Fifth slide" style={{ height: '400px', objectFit: 'cover' }} />
                            </div>
                            <div className="col-12 col-md-4">
                                <img src={img6} className="d-block w-100" alt="Sixth slide" style={{ height: '400px', objectFit: 'cover' }} />
                            </div>
                        </div>
                    </div>
                    {/* Third Group - Mobile shows 1 image, Desktop shows 3 images */}
                    <div className="carousel-item">
                        <div className="row">
                            <div className="col-12 col-md-4">
                                <img src={img7} className="d-block w-100" alt="Seventh slide" style={{ height: '400px', objectFit: 'cover' }} />
                            </div>
                            <div className="col-12 col-md-4">
                                <img src={img8} className="d-block w-100" alt="Eighth slide" style={{ height: '400px', objectFit: 'cover' }} />
                            </div>
                            <div className="col-12 col-md-4">
                                <img src={img1} className="d-block w-100" alt="Ninth slide" style={{ height: '400px', objectFit: 'cover' }} />
                            </div>
                        </div>
                    </div>
                </div>
                {/* Carousel Controls */}
                <a className="carousel-control-prev" href="#carouselExample" role="button" data-bs-slide="prev">
                    <span className="carousel-control-prev-icon" aria-hidden="true"></span>
                    <span className="visually-hidden">Previous</span>
                </a>
                <a className="carousel-control-next" href="#carouselExample" role="button" data-bs-slide="next">
                    <span className="carousel-control-next-icon" aria-hidden="true"></span>
                    <span className="visually-hidden">Next</span>
                </a>
            </div>
        </section>
    );
}

export default CarouselSection;
