import React from "react";
import { Link } from "react-router-dom";

import logoLight from "../assect/images/logo-light.png";

import {
  FiShoppingCart,
  FiDribbble,
  FiLinkedin,
  FiFacebook,
  FiInstagram,
  FiTwitter,
  FiMail,
  FiMapPin,
  FiPhone,
} from "../assect/icons/vander";

export default function Footer() {
  return (
    <>
      <footer className="bg-footer">
        <div className="container">
          <div className="row">
            <div className="col-12">
              <div className="footer-py-60 footer-border">
                <div className="row">
                  <div className="col-lg-5 col-12 mb-0 mb-md-4 pb-0 pb-md-2">
                    <Link to="#" className="logo-footer">
                      <img src={logoLight} width="50px" alt="" />
                    </Link>
                    <p className="mt-4">
                      A great platform to connect with us, ensuring a genuine
                      information about properties.
                    </p>
                    <ul className="list-unstyled social-icon foot-social-icon mb-0 mt-4">
                      {/* <li className="list-inline-item"><Link to="http://linkedin.com/company/shreethemes" target="_blank" className="rounded-3"><FiLinkedin className="fea icon-sm align-middle"/></Link></li> */}
                      <li className="list-inline-item">
                        <Link
                          to="https://www.facebook.com/profile.php?id=61566868843493"
                          target="_blank"
                          className="rounded-3"
                        >
                          <FiFacebook className="fea icon-sm align-middle" />
                        </Link>
                      </li>
                      {/* <li className="list-inline-item">
                        <Link
                          to="https://www.instagram.com/bmi_realstate?igsh=ZW9tMjJhemRlbDZi"
                          target="_blank"
                          className="rounded-3"
                        >
                          <FiInstagram className="fea icon-sm align-middle" />
                        </Link>
                      </li> */}
                      {/* <li className="list-inline-item"><Link to="https://twitter.com/shreethemes" target="_blank" className="rounded-3"><FiTwitter className="fea icon-sm align-middle"/></Link></li> */}
                      <li className="list-inline-item">
                        <Link
                          to="mailto:info@bmiinfratech.com"
                          className="rounded-3"
                        >
                          <FiMail className="fea icon-sm align-middle" />
                        </Link>
                      </li>
                    </ul>
                  </div>

                  <div className="col-lg-2 col-md-4 col-12 mt-4 mt-sm-0 pt-2 pt-sm-0">
                    <h5 className="footer-head">Company</h5>
                    <ul className="list-unstyled footer-list mt-4">
                      <li>
                        <Link to="/aboutus" className="text-foot">
                          <i className="mdi mdi-chevron-right align-middle me-1"></i>{" "}
                          About us
                        </Link>
                      </li>
                      <li>
                        <Link to="/services" className="text-foot">
                          <i className="mdi mdi-chevron-right align-middle me-1"></i>{" "}
                          Services
                        </Link>
                      </li>
                    </ul>
                  </div>

                  <div className="col-lg-2 col-md-4 col-12 mt-4 mt-sm-0 pt-2 pt-sm-0">
                    <h5 className="footer-head">Usefull Links</h5>
                    <ul className="list-unstyled footer-list mt-4">
                      <li>
                        <Link to="/terms" className="text-foot">
                          <i className="mdi mdi-chevron-right align-middle me-1"></i>{" "}
                          Terms of Services
                        </Link>
                      </li>
                      <li>
                        <Link to="/privacy" className="text-foot">
                          <i className="mdi mdi-chevron-right align-middle me-1"></i>{" "}
                          Privacy Policy
                        </Link>
                      </li>

                      <li>
                        <Link to="/contactus" className="text-foot">
                          <i className="mdi mdi-chevron-right align-middle me-1"></i>{" "}
                          Contact us
                        </Link>
                      </li>
                    </ul>
                  </div>

                  <div className="col-lg-3 col-md-4 col-12 mt-4 mt-sm-0 pt-2 pt-sm-0">
                    <h5 className="footer-head">Contact Details</h5>

                    <div className="d-flex mt-4">
                      <FiMapPin className="fea icon-sm text-primary mt-1 me-3" />
                      <div className="">
                        <p className="mb-2">
                          213, Apollo Premier, <br /> Vijay Nagar square, <br />{" "}
                          Indore (M.P), INDIA
                        </p>
                      </div>
                    </div>

                    <div className="d-flex mt-4">
                      <FiMail className="fea icon-sm text-primary mt-1 me-3" />
                      <Link
                        to="mailto:info@bmiinfratech.com"
                        className="text-foot"
                      >
                        info@bmiinfratech.com
                      </Link>
                    </div>

                    <div className="d-flex mt-4">
                      <FiPhone className="fea icon-sm text-primary mt-1 me-3" />
                      <Link to="tel:+916262137006" className="text-foot">
                        +91 6262137006 , 
                      </Link>
                      <Link to="tel:+919009390045" className="text-foot"> 9009390045</Link>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="footer-py-30 footer-bar">
          <div className="container text-center">
            <div className="row">
              <div className="col">
                <div className="text-center">
                  <p className="mb-0">
                    © {new Date().getFullYear()} BMI Real Estate. Design &
                    Develop with <i className="mdi mdi-heart text-danger"></i>{" "}
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </footer>
    </>
  );
}
