import property1 from '../assect/images/property/1.jpg'
import property2 from '../assect/images/property/2.jpg'
import property3 from '../assect/images/property/3.jpg'
import property4 from '../assect/images/property/4.jpg'
import property5 from '../assect/images/property/5.jpg'
import property6 from '../assect/images/property/6.jpg'
import property7 from '../assect/images/property/7.jpg'
import property8 from '../assect/images/property/8.jpg'
import property9 from '../assect/images/property/9.jpg'

import client1 from '../assect/images/client/01.jpg'
import client2 from '../assect/images/client/02.jpg'
import client3 from '../assect/images/client/03.jpg'
import client4 from '../assect/images/client/04.jpg'
import client5 from '../assect/images/client/05.jpg'
import client6 from '../assect/images/client/06.jpg'
import client7 from '../assect/images/client/07.jpg'
// import { FaMapMarkerAlt } from 'react-icons/fa'


export const propertyData = [
    {
        id:1,
        image: property1,
        title: [
            'Residential Plot ',
            'Panchderiya, Ujjain Road ',
            'Near Prestige University ',
            'Indore',
        ],
        desc: [
            'The Residential Plot at Panchderiya, Ujjain Road Near Prestige University, Indore, is a premium offering by BMI Real, a well-known name in the real estate sector. Located in one of Indore rapidly developing areas, this project presents an excellent opportunity for both homebuyers and investors. The plot is strategically situated near Prestige University, offering easy access to top educational institutions, commercial hubs, and key transportation routes. The area is seeing significant infrastructural development, making it an ideal choice for those looking for a long-term investment or a peaceful residential spot. With its prime location, ample space, and growing connectivity, this project promises both modern living and promising future returns.'
        ],
        a : ["Prime Location:"],
        a1 : ["Near Prestige University on Ujjain Road, Indore, providing seamless connectivity."],
        location: ["https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d14707.921061744824!2d75.8589902003702!3d22.840219618828787!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x396303626d3db79f%3A0x23ecc51fb232ea43!2sPanchderiya%2C%20Madhya%20Pradesh%20453555!5e0!3m2!1sen!2sin!4v1731405732976!5m2!1sen!2sin"],
        b :["Gated Community:"],
        b1 :["24/7 security and controlled entry, fostering a safe, serene environment."],
        c : ["Modern Infrastructure:"],
        c1 :["Wide, paved roads, street lighting, and green landscaped areas for a peaceful, healthy lifestyle."],
        d:["Essential Utilities:"],
        d1:["Reliable water, power backup, and efficient sewage systems for hassle-free living."],
        e:["Recreational Amenities:"],
        e1:["Clubhouse, jogging tracks, and community centers for social engagement and wellness."],
        f :["Parking Facilities:"],
        f1 :[" Dedicated parking and visitor spaces for smooth, organized access."],
        g :["Eco-Friendly Features:"],
        g1 :["Rainwater harvesting, on-site fitness center, and green landscaping."],
        h :["Convenient Retail Outlets:"],
        h1 :["Nearby shopping options for essentials and more, ensuring a complete and sustainable lifestyle."],
    },
    {
        id:2,
        image: property2,
        title: [
            'Shubham Arcadia',
        ],
        desc:["Shubham ARCADIA, the flagship project by BMI Real Estate, is a premium 31-acre township designed to offer an exceptional living experience in the heart of Indore. This thoughtfully planned development blends natural beauty with modern infrastructure, creating an ideal environment for those seeking a high-quality lifestyle. Located in one of Indore’s most vibrant and accessible areas, Shubham ARCADIA features meticulously designed plots that cater to a range of needs, whether for building your dream home or making a sound investment. The layout includes wide, well-paved roads and green spaces that provide a sense of tranquility amidst the city’s hustle and bustle. With its focus on both elegance and functionality, Shubham ARCADIA is a community where comfort meets luxury. The development prioritizes creating a peaceful, secure, and welcoming environment, making it an attractive choice for residents and investors alike. BMI Real Estate’s attention to detail and commitment to quality ensure that Shubham ARCADIA isn’t just a place to live, but a long-term investment in your future. With its strategic location and premium design, it stands as one of the most desirable addresses in Indore, offering both a refined lifestyle and promising future returns."],
        // desc2:["Shubham ARCADIA, BMI Real Estate's flagship 31-acre township, combines modern luxury with the serenity of nature, offering a meticulously planned development that caters to a variety of needs. With plots ranging from 600 to 2400 square feet, it provides the perfect opportunity to build your dream home or make a smart investment. The township is easily accessible, with direct connectivity from MR-10 Magarkheda and a wide 30-meter front road ensuring smooth transportation. Designed for the entire family, Shubham ARCADIA features a fully equipped clubhouse, a swimming pool, and an array of recreational facilities, including a cricket pitch, basketball court, and eco-friendly open gym. The community is enhanced by lush green gardens, a children’s park, an elders' park, and a beautifully designed amphitheater, creating spaces for relaxation and social interaction. For spiritual well-being, the township includes a serene temple, while a dedicated yoga and meditation area fosters mental peace. Thoughtfully designed with modern infrastructure, Shubham ARCADIA also boasts underground water and lighting systems, maintaining the aesthetic beauty of the community. The township is further distinguished by its attractive entry gate, seamless road network, and RERA approval, ensuring both quality and long-term value. With its blend of luxury, convenience, and green living, Shubham ARCADIA is set to be one of the most coveted addresses in Indore, offering a truly elevated lifestyle."],
        location:["https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3679.4186880831035!2d75.8941347751916!3d22.74983822936589!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x39630390e137d0d7%3A0x5bef82fe270e4409!2sApollo%20Premier!5e0!3m2!1sen!2sin!4v1731416958953!5m2!1sen!2sin"],
        a:["Flagship Development:"],
        a1:["Shubham ARCADIA, developed by BMI Real Estate, is a premier 31-acre township that combines modern luxury with natural serenity."],
        b :["Plot Variety:"],
        b1 :["Offers a range of plot sizes from 600 to 2400 square feet, ideal for building dream homes or making investment decisions."],
        c : ["Prime Location:"],
        c1 :["A wide 30-meter front road ensures smooth transportation and accessibility."],
        d:["Family-Oriented Amenities:"],
        d1:["Various recreational facilities, including a cricket pitch, basketball court, and eco-friendly open gym."],
        e:["Community Spaces:"],
        e1:["Lush green gardens, a children’s park, an elders' park, and a beautifully designed amphitheater for relaxation and social gatherings."],
        f :["Spiritual and Wellness Facilities"],
        f1 :["A dedicated yoga and meditation area for mental peace."],
        g :["Modern Infrastructure:"],
        g1 :["Underground water and lighting systems to maintain aesthetic appeal"],
        h :["Additional Highlights:"],
        h1 :["RERA-approved development, ensuring quality and value."],
    },
    {
        id:3,
        image: property3,
        title: [
            'Bunglows and Flats ',
            'Shubham Pride ',
            'Rangwasa ',
            'Indore',
        ],
        desc:["Shubham Pride by BMI Real Estate Agency in Rangwasa, Indore, stands as a premium residential project that seamlessly blends luxury, functionality, and an elevated lifestyle. This thoughtfully developed community features both expansive bungalows and stylish flats, designed to meet the diverse needs of families and professionals who seek a balance between tranquility and urban connectivity. Each residence boasts spacious layouts, high ceilings, and large windows that maximize natural light, creating bright, welcoming spaces. The high-end finishes and attention to detail reflect BMI’s commitment to quality, offering residents a sense of sophistication and comfort at every turn. The community’s lush landscaped gardens and serene pathways invite residents to unwind and enjoy nature right at their doorstep. For added convenience, secure and ample parking spaces are provided, along with 24/7 security and surveillance to ensure residents’ peace of mind. Shubham Pride goes beyond the basics with a range of recreational amenities, including a modern clubhouse, fitness facilities, and dedicated play areas for children, fostering a strong sense of community and well-being. This development by BMI Real Estate Agency isn’t just about providing a place to live; it’s about delivering a complete lifestyle experience where residents can relax, connect, and thrive in a well-planned, upscale environment."],
        // desc2:["Shubham Pride by BMI Real Estate Agency redefines upscale living in Rangwasa, Indore, with exclusive amenities that elevate everyday life to new heights. Designed with elegance and functionality in mind, Shubham Pride offers residents a modern clubhouse complete with a state-of-the-art gym, yoga, and wellness rooms, encouraging a holistic lifestyle. For those who love to entertain, there’s a lavish banquet hall and party lawn, perfect for hosting gatherings and celebrations.The outdoor experience is equally enticing, with jogging trails meandering through scenic, green landscapes, water features, and dedicated meditation zones that offer peaceful escapes from the busy world. Families will appreciate the vibrant children’s play areas, designed with safety and creativity to foster active play, along with a spacious multipurpose sports court that accommodates basketball, badminton, and other activities. Shubham Pride also embraces modern convenience with a co-working space and business lounge, catering to remote professionals and entrepreneurs who value productivity without leaving home. With smart home capabilities, including app-controlled lighting and temperature settings, each residence seamlessly combines comfort with cutting-edge technology. Additionally, a concierge service is available to assist with daily needs, from deliveries to reservations, providing a truly luxurious lifestyle. Shubham Pride isn’t just a residential complex; it’s a place where luxury, community, and convenience are redefined for those who want more from life."],
        a:["Upscale Living Redefined:"],
        a1:["Shubham Pride by BMI Real Estate Agency brings luxury to Rangwasa, Indore, with exclusive amenities that elevate everyday living."],
        b :["Elegant and Functional Design:"],
        b1 :["Yoga and wellness rooms for a balanced, holistic lifestyle."],
        c : ["Entertainment and Social Spaces:"],
        c1 :["Lavish banquet hall and party lawn, ideal for hosting gatherings and celebrations."],
        d:["Inviting Outdoor Experiences"],
        d1:["Jogging trails through scenic, green landscapes and water features."],
        e:["Family-Friendly Amenities:"],
        e1:["A spacious multipurpose sports court for basketball, badminton, and other activities."],
        f :["Convenient Workspaces:"],
        f1 :["On-site co-working space and business lounge for remote professionals and entrepreneurs."],
        g :["Smart Home Features:"],
        g1 :["App-controlled lighting and temperature settings for added convenience and comfort."],
        h :["Concierge Service:"],
        h1 :["Assists with daily needs, such as deliveries and reservations, enhancing the luxurious lifestyle."],
        location: ["https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d235648.9399967588!2d75.49412729453127!3d22.653241000000005!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3962ff9c072b72f3%3A0xfd9d1f3e5c09bc5a!2sShubham%20Pride!5e0!3m2!1sen!2sin!4v1731406192875!5m2!1sen!2sin"]
    },
    {
        id:4,
        image: property4,
        title: [
            'Residential Plot and Flats ',
            'Billionaire Park ',
            'Bijalpur ',
            'Indore',
        ],
        desc: ["Billionaire Park, located in the prime locality of Bijalpur, Indore, offers an exclusive collection of residential plots and flats, brought to you by BMI Real Estate Agency. This thoughtfully designed development caters to those who aspire for both luxury and convenience, making it an ideal choice for individuals and families looking to invest in a prestigious property. The plots offer ample space, allowing you to create a personalized living space with freedom and creativity, while the ready-to-move flats are crafted with precision and modern aesthetics, ensuring both comfort and style. Set against a backdrop of natural beauty, Billionaire Park combines the charm of a peaceful residential area with the advantages of being well-connected to the city’s key hubs. This ideal location ensures easy access to schools, shopping centers, healthcare, and transportation, making it an excellent investment for those who seek a balance between serene living and urban conveniences. BMI Real Estate Agency is proud to present this exclusive project, offering not just a home, but a statement of refined living in one of Indore's most desirable neighborhoods."],
        // desc2:["Billionaire Park in Bijalpur, Indore, offers a distinguished living experience with an array of premium amenities designed to elevate your lifestyle. The residential plots provide ample space for customization, while the modern flats are equipped with contemporary finishes and intelligent layouts, offering comfort and convenience in every corner. Residents of Billionaire Park can enjoy 24/7 security with advanced surveillance systems, ensuring peace of mind within a gated community. The development features beautifully landscaped gardens, creating a serene environment perfect for relaxation and outdoor gatherings. For those who enjoy an active lifestyle, there are well-maintained jogging tracks and dedicated fitness zones that encourage wellness and recreation. A fully-equipped clubhouse serves as the hub of social and recreational activities, complete with a lounge, indoor games, and a multipurpose hall for community events. Dedicated parking spaces for residents and visitors ensure convenience and easy accessibility. For added convenience, the development includes modern utilities like round-the-clock water supply, power backup, and well-planned drainage systems. Billionaire Park’s focus on sustainability is reflected in eco-friendly features like rainwater harvesting systems and green spaces, offering an environmentally conscious living experience. Whether you are relaxing in the gardens, engaging with the community in the clubhouse, or enjoying the privacy and serenity of your home, Billionaire Park ensures a lifestyle that combines luxury, convenience, and sustainability."],
        a:["Distinguished Living Experience:"],
        a1:["Offers a luxurious lifestyle with premium amenities in both residential plots and modern flats."],
        b :["Spacious Residential Plots and Modern Flats:"],
        b1 :["Flats come with contemporary finishes and intelligent layouts for comfort and convenience."],
        c : ["24/7 Security:"],
        c1 :["Advanced surveillance systems provide peace of mind in a secure, gated community."],
        d:["Beautifully Landscaped Gardens:"],
        d1:["Serene, green spaces perfect for relaxation and outdoor gatherings."],
        e:["Active Lifestyle Amenities:"],
        e1:["Dedicated fitness zones for wellness and recreation."],
        f :["Fully-Equipped Clubhouse:"],
        f1 :["Includes a lounge, indoor games, and a multipurpose hall for community events."],
        g :["Convenient Parking:"],
        g1 :["Dedicated spaces for residents and visitors for easy accessibility."],
        h :["Holistic Living Experience:"],
        h1 :["A perfect blend of luxury, convenience, and sustainability in every corner of Billionaire Park."],
        location:["https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3681.535132790014!2d75.83527579999999!3d22.671114599999996!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3962fd84edafb5b9%3A0x213f290b7c5eada8!2sShubham%20Billionaire;s%20Park!5e0!3m2!1sen!2sin!4v1731406957484!5m2!1sen!2sin"],
    },
    {
        id:5,
        image: property5,
        title: [
            'Commercial Plot ',
            'Super Corridor ',
            'Indore',
        ],
        desc:["Located in the heart of Indore, the Super Corridor offers an exceptional opportunity for investment with its prime commercial plots, brought to you by BMI Real Estate Agency. This rapidly developing area is at the forefront of Indore’s growth, positioned strategically to cater to the increasing demand for business spaces. The Super Corridor is a dynamic commercial hub, making it an ideal location for retail businesses, offices, and other commercial ventures looking to capitalize on the city's expanding economy. The commercial plots available in this area are well-suited for businesses aiming to establish a presence in a thriving environment with unmatched connectivity. With proximity to major roads, key transportation hubs, and ongoing infrastructure improvements, this location ensures high visibility and accessibility for both customers and employees. BMI Real Estate Agency offers a range of flexible plot sizes, allowing investors to tailor their spaces to fit their unique business needs. Whether you are looking to develop a shopping complex, set up an office building, or invest in commercial real estate, the Super Corridor is poised to provide long-term value and substantial returns. With modern amenities and a well-planned commercial ecosystem, this area promises to be a driving force for Indore’s future business growth."],
        // desc2:["The commercial plots in Super Corridor, Indore, offer a wealth of amenities designed to support the growth and success of your business. With wide, well-paved roads and seamless connectivity to major highways, this location ensures easy access for both employees and customers. The area is equipped with 24/7 security, including surveillance and gated access, providing peace of mind for business owners and their clients. Modern utilities such as uninterrupted power supply, water, and efficient sewage systems are already in place, ensuring smooth business operations without disruption. Super Corridor’s infrastructure also includes dedicated parking spaces, making it easy for customers and staff to access your business without hassle. The area is complemented by green spaces and well-maintained public areas, creating a pleasant, professional environment. Ongoing development projects in the region promise to further enhance the infrastructure, with the potential for increased foot traffic and business opportunities. With its strategic location, modern amenities, and future growth potential, the Super Corridor is an ideal choice for businesses looking to thrive in one of Indore’s most promising commercial districts."],
        a: ["Prime Location for Business Growth:"],
        a1 :["Located in Super Corridor, Indore, a rapidly developing commercial district."],
        b :["Excellent Connectivity:"],
        b1 :["Wide, well-paved roads and seamless access to major highways, ensuring ease for employees and customers."],
        c : ["24/7 Security:"],
        c1 :["Surveillance and gated access provide a safe environment for businesses and clients alike."],
        d:["Reliable Modern Utilities:"],
        d1:["Uninterrupted power supply, water, and efficient sewage systems in place, ensuring smooth business operations."],
        e:["Dedicated Parking:"],
        e1:["Ample parking spaces for customers and staff, allowing hassle-free access to businesses."],
        f :["Professional Environment:"],
        f1 :["Green spaces and well-maintained public areas enhance the overall aesthetic and create a pleasant atmosphere."],
        g :["Infrastructure for Future Growth:"],
        g1 :["Ongoing development projects promise to enhance infrastructure, potentially increasing foot traffic and business opportunities."],
        h :["Ideal for Thriving Businesses:"],
        h1 :["Strategic location, modern amenities, and high growth potential make Super Corridor a top choice for businesses aiming for success in Indore."],
        location: ["https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3678.8972087493034!2d75.81367927515669!3d22.769195679352176!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3963021e763dd25b%3A0x7d72b1b6ad01303e!2sSuper%20Corridor%2C%20Madhya%20Pradesh!5e0!3m2!1sen!2sin!4v1731407224982!5m2!1sen!2sin"],
    },
    
]


export const clientData = [
    {
        image: client1,
        name: 'Young Bonetti',
        title: 'Product Manager',
        desc: '" It seems that only fragments of the original text remain in the Lorem Ipsum texts used today. One may speculate that over the course of time certain letters were added or deleted at various positions within the text. "'
    },
    {
        image: client2,
        name: 'Ofelia Bell',
        title: 'Product Manager',
        desc: '" It seems that only fragments of the original text remain in the Lorem Ipsum texts used today. One may speculate that over the course of time certain letters were added or deleted at various positions within the text. "'
    },
    {
        image: client3,
        name: 'Barbara Carpenter',
        title: 'Product Manager',
        desc: '" It seems that only fragments of the original text remain in the Lorem Ipsum texts used today. One may speculate that over the course of time certain letters were added or deleted at various positions within the text. "'
    },
    {
        image: client4,
        name: 'Jesus Day',
        title: 'Product Manager',
        desc: '" It seems that only fragments of the original text remain in the Lorem Ipsum texts used today. One may speculate that over the course of time certain letters were added or deleted at various positions within the text. "'
    },
    {
        image: client5,
        name: 'Jennifer Smith',
        title: 'Product Manager',
        desc: '" It seems that only fragments of the original text remain in the Lorem Ipsum texts used today. One may speculate that over the course of time certain letters were added or deleted at various positions within the text. "'
    },
    {
        image: client6,
        name: 'Michael Wilkes',
        title: 'Product Manager',
        desc: '" It seems that only fragments of the original text remain in the Lorem Ipsum texts used today. One may speculate that over the course of time certain letters were added or deleted at various positions within the text. "'
    },
]

export const brokerData = [
    {
        image: client4,
        name: 'Lokesh Mujalda',
        
    },
    {
        image: client5,
        name: 'Hitesh Barodia',
        
    },
    {
        image: client6,
        name: 'Divyanshi Bhagat',
        
    },
    {
        image: client7,
        name: 'Shilesh Shukla',
        
    },
]

export const blogData = [
    {
        id:1,
        image: property1,
        tag:'Townter',
        title:'Skills That You Can Learn In The Real Estate Market',
        desc:"The most well-known dummy text is the 'Lorem Ipsum', in the 16th century.",
        date:'13th Sep 2023'
    },
    {
        id:2,
        image: property2,
        tag:'Townter',
        title:'Learn The Truth About Real Estate Industry',
        desc:"The most well-known dummy text is the 'Lorem Ipsum', in the 16th century.",
        date:'29th Nov 2023'
    },
    {
        id:3,
        image: property3,
        tag:'Townter',
        title:'10 Quick Tips About Business Development',
        desc:"The most well-known dummy text is the 'Lorem Ipsum', in the 16th century.",
        date:'29th Dec 2023'
    },
    {
        id:4,
        image: property4,
        tag:'Townter',
        title:'14 Common Misconceptions About Business Development',
        desc:"The most well-known dummy text is the 'Lorem Ipsum', in the 16th century.",
        date:'13th March 2023'
    },
    {
        id:5,
        image: property5,
        tag:'Townter',
        title:'10 Things Your Competitors Can Teach You About Real Estate',
        desc:"The most well-known dummy text is the 'Lorem Ipsum', in the 16th century.",
        date:'5th May 2023'
    },
    {
        id:6,
        image: property6,
        tag:'Townter',
        title:'Why We Love Real Estate',
        desc:"The most well-known dummy text is the 'Lorem Ipsum', in the 16th century.",
        date:'19th June 2023'
    },
    {
        id:7,
        image: property7,
        tag:'Townter',
        title:'110 Quick Tips About Real Estate',
        desc:"The most well-known dummy text is the 'Lorem Ipsum', in the 16th century.",
        date:'20th June 2023'
    },
    {
        id:8,
        image: property8,
        tag:'Townter',
        title:'15 Best Blogs To Follow About Real Estate',
        desc:"The most well-known dummy text is the 'Lorem Ipsum', in the 16th century.",
        date:'31st Aug 2023'
    },
    {
        id:9,
        image: property9,
        tag:'Townter',
        title:'Using Banner Stands To Increase Trade Show Traffic',
        desc:"The most well-known dummy text is the 'Lorem Ipsum', in the 16th century.",
        date:'1st Sep 2023'
    },
]



// const PropertyCard = ({ property }) => {
//     return (
//         <div>
//             <img src={property.image} alt={property.title[0]} />
//    <div>
//   {propertyData.map((property) => (
//     <div key={property.id}>
//       {property.title.map((line, index) => (
//         <div key={index} style={{ display: 'block' }}>
//           {index === 0 && <FaMapMarkerAlt style={{ marginRight: '5px' }} />} {/* Icon only for the first line */}
//           {line}
//         </div>
//       ))}
//     </div>
//   ))}
// </div>
//         </div>
//     );
// };

// Example usage
// const PropertiesList = () => {
//     return (
//         <div>
//             {propertyData.map((property) => (
//                 <PropertyCard key={property.id} property={property} />
//             ))}
//         </div>
//     );
// };



