import { Route, Routes ,useLocation } from "react-router-dom";
import '../node_modules/bootstrap/dist/css/bootstrap.min.css'
import './assect/scss/style.scss'
import './assect/css/materialdesignicons.min.css'
import Footer from "./components/footer";
import ScrollTop from "./components/scrollTop";
import PropertyDetails from "./pages/listing/property-detail";
import PropertyDetailsTwo from "./pages/listing/property-detail-two";
import AboutUs from "./pages/aboutus";
import Features from "./pages/features";
import Faqs from "./pages/faqs";
import Terms from "./pages/terms";
import Privacy from "./pages/privacy";
import ContactUs from "./pages/contactus";
// import Maintenance from "./pages/Special/maintenance";
import Error from "./pages/Special/error";
import Home from "./pages/index/index-six";
import Residential from "./pages/Residential"
import Construction from "./components/Construction";
import Commercial from "./pages/commercial";
import Services from "./pages/service";
import Mining from "./components/mining";


function App() {
  const location = useLocation()
  return (
    <>
    <Routes>
       <Route path="/" element={<Home/>}/>
       <Route path="/ourprojects" element={<Residential/>}/>
       <Route path="/property-detail" element={<PropertyDetails/>}/>
       <Route path="/property-detail/:id" element={<PropertyDetails/>}/>
       <Route path="/property-detail-two" element={<PropertyDetailsTwo/>}/>
       <Route path="/aboutus" element={<AboutUs/>}/>
       <Route path="/faqs" element={<Faqs/>}/>
       <Route path="/terms" element={<Terms/>}/>
       <Route path="/privacy" element={<Privacy/>}/> 
       <Route path="/contactus" element={<ContactUs/>}/>
       <Route path="/mining" element={<Mining/>}/>       
       <Route path="/error" element={<Error/>}/>
       <Route path="*" element={<Error/>}/>
       <Route path="/construction" element={<Construction/>}/>
       {/* <Route path="/commercial" element={<Commercial/>}/> */}
       <Route path="/services" element={<Services/>}/>

       {/* <Route path="/maintenance" element={<Maintenance/>}/> */}



    </Routes>
    <ScrollTop/>
    </>
  );
}

export default App;
