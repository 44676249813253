import React from "react";
import { Link } from "react-router-dom";

import category1 from '../assect/images/property/residential.jpg';
import category4 from '../assect/images/property/industrial.jpg';
import category5 from '../assect/images/property/investment.jpg';

export default function CategoriesTwo() {
    const data = [
        {
            image: category1,
            name: 'Our Projects',
            path: '/Ourprojects'
        },
        {
            image: category4,
            name: 'Construction',
            path: '/construction'
        },
        {
            image: category5,
            name: 'Mining',
            path: '/mining'
        },
    ];
    
    return (
        <>
            <div className="container">
                <div className="row justify-content-center">
                    <div className="col-12 mt-sm-0 pt-sm-0">
                        <div className="features-absolute">
                            <div className="row row-cols-lg-5 row-cols-md-3 row-cols-sm-2 row-cols-1 g-4 mt-0">
                                {data.map((item, index) => (
                                    <div className="col text-center" key={index}>
                                        <div className="categories position-relative overflow-hidden rounded-3 shadow">
                                            <img 
                                                src={item.image} 
                                                className="img-fluid" 
                                                style={{
                                                    height: '155px', 
                                                    margin: '0 auto', 
                                                    display: 'block'
                                                }} 
                                                alt=""
                                            />
                                            <div className="p-3">
                                                <Link to={item.path} className="title text-dark fs-5 fw-medium">
                                                    {item.name}
                                                </Link>
                                            </div>
                                        </div>
                                    </div>
                                ))}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
}
